import React, { useContext } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout, { LanguagePrefixContext } from "../components/Layout"
import Button from "../components/Button"
import { FormattedMessage } from "react-intl"

import "../components/Layout.css"
import "../components/Golf.css"

const GolfTemplate = ({ content, title, ballImage, helmet }) => {
  const containerClassNames = "container mx-auto px-6 lg:px-32"
  const langPrefix = useContext(LanguagePrefixContext)

  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK */}
      <div className="bg-black mx-auto px-6 lg:px-32">
        <img
          src={ballImage.childImageSharp.fixed.src}
          alt=""
          className="md:w-2/3 mx-auto"
        />
      </div>

      <div className={`golf-body ${containerClassNames} py-10`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  )
}

const GolfPage = ({ data, location }) => {
  const { markdownRemark: pageData } = data

  return (
    <Layout location={location}>
      <GolfTemplate
        helmet={
          <Helmet>
            <title>{pageData.frontmatter.title}</title>
            <meta
              name="description"
              content={pageData.frontmatter.mainDescription}
            />
          </Helmet>
        }
        content={pageData.html}
        {...pageData.frontmatter}
      />
    </Layout>
  )
}

export default GolfPage

export const pageQuery = graphql`
  query GolfPageItem($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        mainDescription
        ballImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
